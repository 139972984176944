.App {
  text-align: center;
}

.centerdiv {
  left: 50%;
  top: 50%;
  position: fixed;
  text-align: center;
  width: 800px;
  height: 100px;
  margin-left: -400px;
  margin-top: -100px;
}

.pbold {
  font-weight: bold;
}